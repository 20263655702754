import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "components/loader";
import UploadIcon from "assets/icons/upload_icon.svg";
import Upload from "assets/upload.svg";
import Fileicon from "assets/fileicon.svg";
import Redwarning from "assets/exclamanation.svg";
import bwarning from "assets/warning.svg";
import CrossSignal from "assets/cross-circle_red.svg";
import Closebutton from "assets/closeicon.svg";
export default function FileUploadPopUp({
  openFilePopUp,
  setFilePopUp,
  onFileUpload,
  fileValue,
  fileStatus,

  handleDeleteFile,
  setStepsData,
}) {
  const [selectFile, setSelectFile] = useState(false);

  const handleClose = () => {
    if (fileValue.length === 0) {
      setSelectFile(true);
    } else {
      setSelectFile(false);
      setFilePopUp(false);
    }

    // setLoader(false)
  };

  return (
    <>
      {openFilePopUp && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-primary">
            <div className="relative  flex items-center justify-between lg:max-w-3xl md:max-w-2xl  sm:max-w-xl mx-auto  bg-white flex-col rounded-2xl px-4 sm:px-[40px]  py-[40px] w-11/12 sm:w-auto">
              <div className="flex justify-between w-full items-center">
                <h5 className="font-secondary text-[24px]  w-full font-semibold mb-4">
                  Upload your files
                </h5>

                <img
                  src={Closebutton}
                  alt="/"
                  onClick={() => {
                    setFilePopUp(false);
                    setSelectFile(false);
                  }}
                  className="cursor-pointer  h-6 mb-2  bg-white p-1 rounded-md border-2 border-[#0000001a] "
                />
              </div>
              <div
                className={`w-full ${
                  fileValue?.length != 0
                    ? "border-2 border-[#2A7CF8] p-2 sm:p-5"
                    : ""
                }   w-full h-auto rounded-lg mt-4  flex items-center justify-center relative flex-col`}
              >
                <div className="w-full flex items-center justify-center">
                  <input
                    multiple
                    type="file"
                    placeholder=".pdf, .docx, .txt"
                    value=""
                    className={`inputfile cursor-pointer ${
                      fileValue?.length != 0 ? "h-[144px]" : "h-[106px]"
                    }`}
                    onChange={onFileUpload}
                    onClick={() => setSelectFile(false)}
                  />
                </div>

                <div className="flex flex-col bg-[#F9FBFF] border-dashed border border-[#00000040] w-full rounded-lg p-5 font-arial text-new text-quotetext-200 ">
                  <div className="w-full mr-2 flex gap-2 font-arial mb-3 sm:mb-0 items-center justify-center">
                    <img src={Upload} alt="upload" />
                    <p className="text-xl font-semibold text-black leading-6 font-opensans">
                      Drag & drop files here or{" "}
                      <span className="text-[#2A7CF8]">click to add</span>
                    </p>
                  </div>
                  <p className="text-sm text-center pt-4 font-opensans">
                    We support{" "}
                    <span className="text-[#2A7CF8]">
                      .pdf, .docx, and .txt
                    </span>
                    , files up to 100MB each,
                    <br />
                    with no limit on the total number of files.
                  </p>
                </div>
                {selectFile ? (
                  <span className="text-center font-secondary text-red transition-all scale-110 duration-100">
                    Please select File
                  </span>
                ) : null}
                <div
                  className={`${
                    fileValue?.length != 0
                      ? "max-h-[134px] mt-8 bg-[#F9FBFF] rounded-md sm:px-2 py-2 w-full overflow-y-auto"
                      : ""
                  } `}
                >
                  {fileStatus !== "loading"
                    ? fileValue?.map(
                        ({
                          originalName,
                          id,
                          noOfWords,
                          documentSize,
                          documentType,
                          message,
                        }) => (
                          <div
                            className={`flex justify-between gap-1  items-center w-full ${
                              noOfWords == 0
                                ? "border border-[#ff0000]"
                                : "border border-[#2A7CF8]"
                            }  mb-2  p-4 rounded-xl bg-[#F8F9F9]`}
                            key={id}
                          >
                            <div className="md:flex  justify-between w-full items-center gap-12">
                              <div className="flex items-center gap-2 font-arial w-full">
                                {noOfWords != 0 ? (
                                  <img src={Fileicon} alt="/" />
                                ) : (
                                  <div className="relative">
                                    <img src={Fileicon} alt="/" />
                                    <img
                                      src={CrossSignal}
                                      alt=""
                                      className="absolute bottom-[-3px] right-[-4px]"
                                    />
                                  </div>
                                )}

                                <p className="flex flex-col">
                                  <span
                                    className="text-[#0B0B0B]  font-opensans text-sm font-normal"
                                    // style={{ wordWrap: "break-word" }}
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {originalName}{" "}
                                  </span>
                                  {noOfWords != 0 ? (
                                    <span className="text-[#8B8B8B] text-[12px] font-opensans font-normal">
                                      {documentSize} {documentType}
                                    </span>
                                  ) : (
                                    <span className="text-[#ff0000] text-[12px] font-opensans font-normal">
                                      {documentSize} {documentType}
                                    </span>
                                  )}
                                  {noOfWords == 0 && (
                                    <p className="flex gap-1 items-center sm:text-[12px] text-[#ff0000] font-arial">
                                      <img src={Redwarning} alt="" />
                                      {message}
                                    </p>
                                  )}
                                </p>
                              </div>
                              <div className="flex gap-2">
                                {noOfWords != 0 ? (
                                  <p className="w-max sm:text-[12px] text-[#8B8B8B] font-arial">
                                    {`(Total Words: ${noOfWords})`}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <div
                                  onClick={() => handleDeleteFile(id)}
                                  className="hidden md:block cursor-pointer  h-6 bg-transparent filter-black"
                                >
                                  <svg
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      id="Vector"
                                      d="M0.314703 1.0647C0.414208 0.964947 0.532416 0.8858 0.662555 0.831798C0.792695 0.777796 0.93221 0.75 1.07311 0.75C1.21401 0.75 1.35352 0.777796 1.48366 0.831798C1.6138 0.8858 1.73201 0.964947 1.83152 1.0647L7.50028 6.73561L13.169 1.0647C13.2686 0.965108 13.3869 0.886104 13.517 0.832204C13.6471 0.778303 13.7866 0.750561 13.9275 0.750561C14.0683 0.750561 14.2078 0.778303 14.3379 0.832204C14.468 0.886104 14.5863 0.965108 14.6859 1.0647C14.7855 1.1643 14.8645 1.28254 14.9184 1.41266C14.9723 1.54279 15 1.68226 15 1.82311C15 1.96396 14.9723 2.10343 14.9184 2.23356C14.8645 2.36368 14.7855 2.48192 14.6859 2.58152L9.01495 8.25028L14.6859 13.919C14.7855 14.0186 14.8645 14.1369 14.9184 14.267C14.9723 14.3971 15 14.5366 15 14.6775C15 14.8183 14.9723 14.9578 14.9184 15.0879C14.8645 15.218 14.7855 15.3363 14.6859 15.4359C14.5863 15.5355 14.468 15.6145 14.3379 15.6684C14.2078 15.7223 14.0683 15.75 13.9275 15.75C13.7866 15.75 13.6471 15.7223 13.517 15.6684C13.3869 15.6145 13.2686 15.5355 13.169 15.4359L7.50028 9.76495L1.83152 15.4359C1.73192 15.5355 1.61368 15.6145 1.48356 15.6684C1.35343 15.7223 1.21396 15.75 1.07311 15.75C0.932261 15.75 0.79279 15.7223 0.662663 15.6684C0.532535 15.6145 0.414299 15.5355 0.314703 15.4359C0.215108 15.3363 0.136104 15.218 0.0822038 15.0879C0.0283032 14.9578 0.000560843 14.8183 0.000560843 14.6775C0.000560843 14.5366 0.0283032 14.3971 0.0822038 14.267C0.136104 14.1369 0.215108 14.0186 0.314703 13.919L5.98561 8.25028L0.314703 2.58152C0.214947 2.48201 0.1358 2.3638 0.0817982 2.23366C0.0277962 2.10352 0 1.96401 0 1.82311C0 1.68221 0.0277962 1.5427 0.0817982 1.41256C0.1358 1.28242 0.214947 1.16421 0.314703 1.0647Z"
                                      fill="#000000"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>

                            <div
                              onClick={() => handleDeleteFile(id)}
                              className="block md:hidden cursor-pointer  h-6 bg-transparent filter-black"
                            >
                              <svg
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="Vector"
                                  d="M0.314703 1.0647C0.414208 0.964947 0.532416 0.8858 0.662555 0.831798C0.792695 0.777796 0.93221 0.75 1.07311 0.75C1.21401 0.75 1.35352 0.777796 1.48366 0.831798C1.6138 0.8858 1.73201 0.964947 1.83152 1.0647L7.50028 6.73561L13.169 1.0647C13.2686 0.965108 13.3869 0.886104 13.517 0.832204C13.6471 0.778303 13.7866 0.750561 13.9275 0.750561C14.0683 0.750561 14.2078 0.778303 14.3379 0.832204C14.468 0.886104 14.5863 0.965108 14.6859 1.0647C14.7855 1.1643 14.8645 1.28254 14.9184 1.41266C14.9723 1.54279 15 1.68226 15 1.82311C15 1.96396 14.9723 2.10343 14.9184 2.23356C14.8645 2.36368 14.7855 2.48192 14.6859 2.58152L9.01495 8.25028L14.6859 13.919C14.7855 14.0186 14.8645 14.1369 14.9184 14.267C14.9723 14.3971 15 14.5366 15 14.6775C15 14.8183 14.9723 14.9578 14.9184 15.0879C14.8645 15.218 14.7855 15.3363 14.6859 15.4359C14.5863 15.5355 14.468 15.6145 14.3379 15.6684C14.2078 15.7223 14.0683 15.75 13.9275 15.75C13.7866 15.75 13.6471 15.7223 13.517 15.6684C13.3869 15.6145 13.2686 15.5355 13.169 15.4359L7.50028 9.76495L1.83152 15.4359C1.73192 15.5355 1.61368 15.6145 1.48356 15.6684C1.35343 15.7223 1.21396 15.75 1.07311 15.75C0.932261 15.75 0.79279 15.7223 0.662663 15.6684C0.532535 15.6145 0.414299 15.5355 0.314703 15.4359C0.215108 15.3363 0.136104 15.218 0.0822038 15.0879C0.0283032 14.9578 0.000560843 14.8183 0.000560843 14.6775C0.000560843 14.5366 0.0283032 14.3971 0.0822038 14.267C0.136104 14.1369 0.215108 14.0186 0.314703 13.919L5.98561 8.25028L0.314703 2.58152C0.214947 2.48201 0.1358 2.3638 0.0817982 2.23366C0.0277962 2.10352 0 1.96401 0 1.82311C0 1.68221 0.0277962 1.5427 0.0817982 1.41256C0.1358 1.28242 0.214947 1.16421 0.314703 1.0647Z"
                                  fill="#000000"
                                />
                              </svg>
                            </div>
                            {/* <FontAwesomeIcon
                        icon={faTrash}
                        className="text-red w-6 cursor-pointer"
                        onClick={() => handleDeleteFile(id)}
                      /> */}
                          </div>
                        )
                      )
                    : //  fileStatus === "loading" ? (
                      //   <Loader />
                      // ) :
                      null}
                </div>
              </div>

              <button
                className={`w-52 mt-8 ${
                  fileValue?.length != 0 ? " bg-orange" : "bg-[#00000033] "
                } rounded-3xl h-11 text-xl py-1 text-white  
                font-opensans font-bold block mx-auto`}
                onClick={handleClose}
              >
                {fileStatus === "loading" ? <Loader /> : "Continue"}
              </button>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>
      )}
    </>
  );
}
