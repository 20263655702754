import React, { useState, useRef, useEffect, useContext } from "react";
// import QuoteCard from "./QuoteCard";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import TranslateFrom from "./firstCompInputs/TranslateFrom";
import TranslateTo from "./firstCompInputs/TranslateTo";
import ServiceInput from "./firstCompInputs/ServiceInput";
import SubjectInput from "./firstCompInputs/SubjectInput";
import UploadInput from "./firstCompInputs/UploadInput";
import QuotePopUp from "components/popup/quotePopUp/index";
// import QuoteSingleForm from "./QuoteSingleForm";
import { usePostLanguages } from "hooks/Quotes/usePostLanguages";
import { usePostFileUpload } from "hooks/Quotes/usePostFileUpload";
import FileUploadPopUp from "components/popup/quotePopUp/fileUploadPopUp";
import { UseDeleteFiles } from "hooks/Quotes/useDeleteFile";
import DataProvider from "Context/DataContext";
import loadable from "@loadable/component";
import PricingSection from "./firstCompInputs/priceSection";
import SecondStep from "../secondComp/SecondStep";

function FirstStep({
  setStep,
  selected,
  step,
  setContext,
  setSelected,
  context,
}) {
  const {
    stepsData,
    setStepsData,
    transtalteFromInput,
    setTranslateFromInput,
    transtalteToInput,
    setTranslateToInput,
    fileValue,
    setfileValue,
    numberOfWords,
    setNumberOfWords,
    storeValue,
    setStoreValue,
    transtalteServiceInput,
    setTranstalteServiceInput,
    transtalteSubjectInput,
    setTranstalteSubjectInput,
    AllData,
    quoteId,
    setQuoteId,
    LocalObject,
    translateMarketingSourceInput,
    setTranslateMarketingSourceInput,
    scrollupform,
  } = useContext(DataProvider);

  // data saving process
  const quote_id =
    LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("quote_id")
      : "";
  const [errorOne, setErrorOne] = useState(false);
  const [errorTwo, setErrorTwo] = useState(false);
  const [errorThree, setErrorThree] = useState(false);
  const [newError, setNewError] = useState(false);

  // const { data: Data } = useStepsData(quote_id);
  // setQuoteId(Data?.data?.quote?.id)
  //

  useEffect(() => {
    setfileValue(stepsData?.data?.quote_documents);
    setNumberOfWords(stepsData?.data?.quote?.number_of_words);
    if (stepsData?.data?.quote?.number_of_words) {
      setErrorThree(false);
    }
    setTranslateFromInput(stepsData?.data?.quote?.source_language);
    let targetLanguageFromApi = [];
    stepsData?.data?.quote_items.map(({ target_language }) => {
      targetLanguageFromApi.push(target_language);
    });
    setStoreValue(targetLanguageFromApi);
  }, [stepsData]);

  // data saving  process

  // post data
  const { status, mutateAsync } = usePostLanguages();
  const { status: fileStatus, mutateAsync: FileMutateAsync } =
    usePostFileUpload();
  const ctd = useContext(DataProvider);

  const [callAPI, setCallAPI] = useState(false);

  //  toggle
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFilePopUp, setFilePopUp] = useState(false);
  //  transation subject hooks
  const [subjectOpen, setSubjectOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const subjectInputRef = useRef(null);
  // translate optional service hooks

  const [serviceOpen, setServiceOpen] = useState(false);
  const serviceInputRef = useRef(null);

  // translate from input hooks
  const [TransFromOpen, setTransFromOpen] = useState(false);
  const [FromSearchInput, setFromSearchInput] = useState("");
  const TranslateFromRef = useRef(null);
  // translate to input hooks
  const TranslateToRef = useRef(null);
  const [ToSearchInput, setToSearchInput] = useState("");
  const [TransToOpen, setTransToOpen] = useState(false);

  const [sameLanguageError, setSameLanguageError] = useState(false);
  const [showGridSection, setShowGridSection] = useState(false);

  // const [quoteId, setQuoteId] = useState(0);
  // hide and show select
  const TranslateServiceStyles = {
    display: serviceOpen ? "block" : "none",
  };
  const TranslateSubjectStyles = {
    display: subjectOpen ? "block" : "none",
  };
  const TranslateFromstyles = {
    display: TransFromOpen ? "block" : "none",
  };
  const TranslateTostyles = {
    display: TransToOpen ? "block" : "none",
  };
  const { data } = useFetchLanguages();

  //  onclick functions
  const handleTranslateFromInput = (lang) => {
    setTranslateFromInput(lang);
    setTransFromOpen(!TransFromOpen);
    setFromSearchInput("");
  };

  const handleRemoveLanguage = (value) => {
    const newArr = [...storeValue];
    const index = newArr.findIndex((preValue) => preValue === value);
    if (index >= 0) {
      newArr.splice(index, 1);
      setStoreValue(newArr);
    }
  };
  const onSubmit = () => {
    let langOne = transtalteFromInput.toLowerCase();
    let langTwo = transtalteToInput.toLowerCase();

    if (langOne === langTwo) {
      setSameLanguageError(true);
    } else {
      // setShowLoader(true);
      // setShowLoader(true);
      // setShowGridSection(false);
      const postData = {
        sourceLanguage: transtalteFromInput,
        targetLanguages: storeValue.toString(),
        noOfWords: numberOfWords,
        quote_id: quoteId,
        category_id: transtalteServiceInput.category_id,
        sub_category_id: transtalteSubjectInput.subcategory_id,
        marketing_source: translateMarketingSourceInput,
      };
      mutateAsync(postData)
        .then(function (response) {
          let id = response?.data?.quote?.id;
          localStorage.setItem("quote_id", id);
          setQuoteId(id);
          setContext(response);
          setStepsData(response);
        })
        .catch((err) => {
          setShowLoader(false);
        });

      if (
        transtalteFromInput !== "Select source language" &&
        postData.noOfWords !== 0 &&
        TransToOpen === false
      ) {
        setShowLoader(false);
        setShowGridSection(!showGridSection);
        setStep((prevStep) => (prevStep = "1"));
        setSelected((selected) => (selected = "1"));
        localStorage.setItem("step", 1);
        localStorage.setItem("quote_id", quoteId);
        localStorage.setItem("word_count", numberOfWords);
      }
    }
  };

  const onFileUpload = (e) => {
    const files = Array.from(e.target.files);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("document[]", file);
    });
    formData.append("quote_id", quoteId);

    FileMutateAsync(formData).then((response) => {
      const FileResponse = response?.data?.quote_documents;
      setfileValue([...FileResponse]);
      setStepsData(response);
    });
  };

  const handleDeleteFile = (id) => {
    UseDeleteFiles(id, fileValue).then((res) => {
      if (res?.data?.quote_documents) {
        setfileValue(res.data?.quote_documents);
      }
    });
  };

  const secondStepRef = useRef(null);
  const scrollToSecondStep = () => {
    if (secondStepRef.current) {
      secondStepRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    scrollToSecondStep();
  }, [secondStepRef.current]);

  // const handleDeleteFile = (id) => {

  //   UseDeleteFiles(id).then((res) => setfileValue(res.data?.quote_documents));
  // };
  const HandleonSubmit = () => {
    // scrollToSecondStep();

    if (
      transtalteFromInput !== "Select source language" &&
      storeValue.length !== 0
    ) {
      onSubmit();
    }

    //CUSTOM ERROR HANDLING
    // setErrorOne(false)
    // setErrorTwo(false)
    // setErrorThree(false)

    if (transtalteFromInput == undefined) {
      setErrorOne(true);
    }

    if (transtalteToInput) {
      setErrorTwo(true);
    }

    if (!numberOfWords) {
      setErrorThree(true);
    }

    //CUSTOM ERROR HANDLING
  };

  const storeValues = storeValue.length !== 0;

  //    hide when click outside the div
  const translatevalue = !(transtalteFromInput && storeValues && numberOfWords);

  const handleTranslateToInput = (lang) => {
    setTranslateToInput(lang);
    setToSearchInput("");
    if (!storeValue.includes(lang)) {
      setStoreValue([...storeValue, lang]);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        TranslateFromRef.current &&
        !TranslateFromRef.current.contains(event.target)
      ) {
        setTransFromOpen(false);
      }
      if (
        TranslateToRef.current &&
        !TranslateToRef.current.contains(event.target)
      ) {
        setTransToOpen(false);
        if (callAPI) {
          setCallAPI(false);
          if (
            transtalteFromInput !== "Select source language" &&
            storeValue.length !== 0
          ) {
            onSubmit();
          }
        }
      }
      if (
        serviceInputRef.current &&
        !serviceInputRef.current.contains(event.target)
      ) {
        setServiceOpen(false);
      }
      if (
        subjectInputRef.current &&
        !subjectInputRef.current.contains(event.target)
      ) {
        setSubjectOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    callAPI,
    TranslateFromRef,
    TranslateToRef,
    serviceInputRef,
    subjectInputRef,
    storeValue,
  ]);

  useEffect(() => {
    if (showGridSection) {
      if (translatevalue || !numberOfWords) {
        setShowGridSection(false);
      }
    }
  }, [storeValue.length, numberOfWords]);

  // const QuoteSingleForm = loadable(() => import("./QuoteSingleForm"));
  const QuoteCard = loadable(() => import("./QuoteCard"));
  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: "0px",
          background: "rgba(255,255,255,0.4)",
          zIndex: 10000,
          display: showLoader ? "block" : "none",
        }}
      >
        <div className="flex justify-center items-center">
          <div
            className="
        loader
        ease-linear
        rounded-full
        border-2 border-t-2 border-gray-200
        h-5
        w-5
      "
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%)",
            }}
          ></div>
        </div>
      </div>
      <div className="flex  justify-center flex-col items-center xl:gap-10 w-full 2xl:w-[65%] xl:w-10/12  max-w-screen-2xl px-4 xl:px-0">
        <div className="flex items-center justify-center flex-col w-full relative">
          <h5 className="font-secondary font-semibold text-4xl pt-5 pb-3 text-center">
            What would you like to translate?
          </h5>
          <p className="font-opensans leading-8 py-4 sm:mx-auto text-center px-4  w-full xl:w-9/12 text-lg">
            You can get an instant quote or{" "}
            <span
              className="text-orange underline cursor-pointer"
              onClick={() => setOpen(true)}
            >
              send us your contact details
            </span>
            &nbsp; and one of our team members will get back to you within a few
            minutes.
          </p>
          <div className="absolute top-[-80px]">
            <QuotePopUp open={open} setOpen={setOpen} />
          </div>
          <FileUploadPopUp
            setFilePopUp={setFilePopUp}
            openFilePopUp={openFilePopUp}
            onFileUpload={onFileUpload}
            fileValue={fileValue}
            fileStatus={fileStatus}
            handleDeleteFile={handleDeleteFile}
          />
          <form
            ref={scrollupform}
            className="mt-5  grid gap-0 sm:gap-4 lg:gap-8 w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 "
          >
            <TranslateFrom
              data={data}
              AllData={AllData}
              TranslateFromRef={TranslateFromRef}
              TransFromOpen={TransFromOpen}
              transtalteFromInput={transtalteFromInput}
              setTransFromOpen={setTransFromOpen}
              TranslateFromstyles={TranslateFromstyles}
              FromSearchInput={FromSearchInput}
              setFromSearchInput={setFromSearchInput}
              handleTranslateFromInput={handleTranslateFromInput}
              errorOne={errorOne}
              errorTwo={errorTwo}
              setErrorTwo={setErrorTwo}
              setErrorOne={setErrorOne}
              setNewError={setNewError}
              sameLanguageError={sameLanguageError}
            />
            <TranslateTo
              setCallAPI={setCallAPI}
              data={data}
              TranslateToRef={TranslateToRef}
              setTransToOpen={setTransToOpen}
              TransToOpen={TransToOpen}
              transtalteToInput={transtalteToInput}
              storeValue={storeValue}
              transtalteFromInput={transtalteFromInput}
              TranslateFromstyles={TranslateFromstyles}
              TranslateTostyles={TranslateTostyles}
              ToSearchInput={ToSearchInput}
              handleTranslateToInput={handleTranslateToInput}
              setToSearchInput={setToSearchInput}
              handleRemoveLanguage={handleRemoveLanguage}
              setErrorTwo={setErrorTwo}
              errorTwo={errorTwo}
              numberOfWords={numberOfWords}
              newError={newError}
              setNewError={setNewError}
            />
            {/* {console.log(transtalteToInput)} */}
            <div className="">
              <UploadInput
                fileValue={fileValue}
                setfileValue={setfileValue}
                setFilePopUp={setFilePopUp}
                setNumberOfWords={setNumberOfWords}
                numberOfWords={numberOfWords}
                errorThree={errorThree}
                setErrorThree={setErrorThree}
                handleDeleteFile={handleDeleteFile}
                transtalteFromInput={transtalteFromInput}
                storeValue={storeValues}
                setErrorOne={setErrorOne}
                setErrorTwo={setErrorTwo}
              />
              <div className="flex items-start">
                {/* <p className="lg:ml-3 text-[12px] text-[#978B89] font-opensans leading-6 ">
                  SSL TLS and PCI DSS Compliant ✔ <br /> Check our
                  <span className="text-orange"> security policy </span>
                  to learn how we keep your data safe.
                </p> */}
              </div>
            </div>
          </form>
          <div className="mb-6 w-full items-center pt-[40px]">
            <p
              className=" font-secondary cursor-pointer text-left w-max text-xl font-medium flex items-center"
              onClick={() => setToggle((open) => !open)}
            >
              <span className="bg-black w-4 h-4 mb-1 mr-2.5 rounded-full inline-flex items-center justify-center ">
                {" "}
                <i
                  className={`${
                    toggle ? "rotate-[135deg]" : "rotate-45"
                  } small-arrow transition-all duration-100 ease-linear `}
                ></i>
              </span>{" "}
              Project Details (Optional){" "}
            </p>
          </div>
          {toggle ? (
            <>
              <div className="xl:ml-[13px] grid gap-0 sm:gap-4 lg:gap-8 w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:ml-[57px]">
                <ServiceInput
                  data={data}
                  setServiceOpen={setServiceOpen}
                  serviceOpen={serviceOpen}
                  transtalteServiceInput={transtalteServiceInput}
                  TranslateServiceStyles={TranslateServiceStyles}
                  serviceInputRef={serviceInputRef}
                  setTranstalteServiceInput={setTranstalteServiceInput}
                />

                <SubjectInput
                  data={data}
                  setSubjectOpen={setSubjectOpen}
                  subjectOpen={subjectOpen}
                  transtalteSubjectInput={transtalteSubjectInput}
                  TranslateSubjectStyles={TranslateSubjectStyles}
                  subjectInputRef={subjectInputRef}
                  setTranstalteSubjectInput={setTranstalteSubjectInput}
                />
                <div className="flex flex-wrap lg:flex-nowrap w-full 2xl:w-11/12 lg:w-11/12 justify-center lg:justify-between">
                  <label className="flex flex-col w-full font-opensans">
                    <span className="text-[18px]">
                      How did you hear about us ?
                    </span>

                    <select
                      onChange={(e) =>
                        setTranslateMarketingSourceInput(e.target.value)
                      }
                      className="h-[50px]  w-full  min-w-[280px] lg:w-[325px] bg-white font-arial px-4 rounded-xl border border-quotetext-100 border-solid text-[#777777] mb-5"
                    >
                      {data
                        ? data?.marketing_source?.map(({ text, id }) => (
                            <option
                              value={id}
                              selected={
                                id === translateMarketingSourceInput
                                  ? "selected"
                                  : ""
                              }
                            >
                              {text}
                            </option>
                          ))
                        : null}
                    </select>
                  </label>
                </div>
              </div>
            </>
          ) : null}
          <div className=" flex items-center justify-center w-full mt-5 mb-8 lg:mb-0">
            <div className=" flex justify-center items-center">
              <button
                className={`text-lg sm:text-[22px] font-bold text-white font-opensans px-6 py-4 sm:py-2 rounded-full sm:rounded-3xl 
                    
                    bg-orange cursor-pointer
                   uppercase lg:mr-3.5 2xl:mr-0 mx-auto w-full sm:w-auto`}
                // onClick={HandleonSubmit}
                onClick={() => {
                  scrollToSecondStep();
                  HandleonSubmit();
                }}
              >
                Show Price And Time
              </button>
            </div>
          </div>
          <div className="w-full bg-gray h-[1px] mt-5"></div>
        </div>

        {showGridSection ? (
          <div className="w-full" ref={secondStepRef}>
            <SecondStep
              context={context}
              setStep={setStep}
              step={step}
              setSelected={setSelected}
              translatevalue={translatevalue}
              transtalteFromInput={transtalteFromInput}
              errorOne={errorOne}
              setErrorOne={setErrorOne}
              storeValue={storeValues}
              numberOfWords={numberOfWords}
              setNumberOfWords={setNumberOfWords}
              errorTwo={errorTwo}
              setErrorTwo={setErrorTwo}
              errorThree={errorThree}
              setErrorThree={setErrorThree}
            />
          </div>
        ) : (
          <PricingSection
            context={context}
            setStep={setStep}
            step={step}
            setSelected={setSelected}
            translatevalue={translatevalue}
            transtalteFromInput={transtalteFromInput}
            errorOne={errorOne}
            setErrorOne={setErrorOne}
            storeValue={storeValues}
            numberOfWords={numberOfWords}
            setNumberOfWords={setNumberOfWords}
            errorTwo={errorTwo}
            setErrorTwo={setErrorTwo}
            errorThree={errorThree}
            setErrorThree={setErrorThree}
            fileValue={fileStatus}
          />
        )}

        {/* <QuoteSingleForm
          backgroundColor="#ffffff"
          textColour="darkBlue"
          text="No need to pay now. Just send your email or phone number and we'll
              get to work."
          buttonBg="darkBlue"
          placeholdertext="Enter your email / phone number"
          lgInputWidth="w-72"
        /> */}
        <QuoteCard />
      </div>
    </>
  );
}

export default FirstStep;

// import React, { useRef } from "react";

// export default function FirstStep() {
//   const divRef = useRef();

//   return (
//     <div className="App">
//       <button
//         onClick={() => {
//           divRef.current.scrollIntoView({ behavior: "smooth" });
//         }}
//       >
//         Scroll to Bottom
//       </button>
//       <div>
//         <div className="bigDiv">next js </div>
//         <div className="bigDiv">reactjs</div>
//         <div className="bigDiv">hello world</div>
//         <div className="bigDiv" ref={divRef}>
//           Scrolled Here
//         </div>
//       </div>
//     </div>
//   );
// }
