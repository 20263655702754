import React, { useState } from "react";

function TranslateFrom({
  data,
  AllData,
  TranslateFromRef,
  TransFromOpen,
  transtalteFromInput,
  setTransFromOpen,
  TranslateFromstyles,
  FromSearchInput,
  setFromSearchInput,
  handleTranslateFromInput,
  errorOne,
  setNewError,
  setErrorOne,
  setErrorTwo,
  sameLanguageError,
}) {
  // console.log(transtalteFromInput);
  const [hoveredItem, setHoveredItem] = useState(null);
  if (transtalteFromInput) {
    setNewError(false);
    setErrorOne(false);
    // setErrorTwo(false)
  }
  const handleOptionHover = (source_language) => {
    setHoveredItem(source_language);
  };
  // if (transtalteToInput == "Select target language") {
  // }
  const handleOpClick = () => {
    setTransFromOpen(false);
    // setLanguageSelection(false);
  };
  return (
    <>
      <label className="flex flex-col w-full font-opensans mb-5 cursor-pointer items-center">
        <div className="w-full ">
          <span className="text-[18px] text-left font-opensans ">
            Translate from
          </span>
          <div
            className=" w-full  min-w-[300px] lg:w-[325px] font-arial group relative"
            ref={TranslateFromRef}
          >
            <div className="hidden lg:block">
              <div
                className={` h-[50px] px-4 bg-white  rounded-xl border w-full border-solid ${
                  errorOne ? "border-[#ff0000]" : "border-quotetext-100"
                } text-[#777777] mb-1 flex items-center justify-between`}
                onClick={() => setTransFromOpen(!TransFromOpen)}
              >
                <p>
                  {transtalteFromInput
                    ? transtalteFromInput
                    : "Select source language"}
                </p>
                <span
                  className={`text-quotetext-200 arrow ${
                    TransFromOpen ? "rotate-[316deg]" : "rotate-[135deg]"
                  }  transition-all duration-200 ease-linear  `}
                ></span>
              </div>

              <input
                placeholder="Search..."
                style={TranslateFromstyles}
                type="text"
                className={`h-[50px] px-4 absolute z-50 bg-white border-quotetext-100 rounded-tl-lg rounded-tr-lg outline-none border w-full border-solid text-[#777777]  flex items-center justify-between`}
                value={FromSearchInput}
                onChange={(e) => setFromSearchInput(e.target.value)}
              />
              <ul
                className="rounded-bl-lg rounded-br-lg max-h-[350px] visible absolute z-50 top-[100px] w-full  overflow-y-scroll bg-white border-quotetext-100 border-solid border"
                style={TranslateFromstyles}
              >
                {data
                  ? data?.data
                      ?.filter(({ source_language }) => {
                        if (FromSearchInput === "") {
                          return source_language;
                        } else if (
                          source_language
                            .toLowerCase()
                            .includes(FromSearchInput.toLowerCase())
                        ) {
                          return source_language;
                        }
                      })
                      ?.map(({ source_language, priority }) => (
                        <li
                          className={`h-[50px] px-4 w-full text-quotetext-200 flex items-center justify-between ${
                            priority === 0
                              ? "border-b border-quotetext-100"
                              : ""
                          }`}
                          onClick={() =>
                            handleTranslateFromInput(source_language)
                          }
                        >
                          {source_language}
                        </li>
                      ))
                  : null}
              </ul>
            </div>
            <div className="block lg:hidden">
              <div
                className={`h-[50px] px-4 bg-white  rounded-xl border w-full border-solid ${
                  errorOne ? "border-[#ff0000]" : "border-quotetext-100"
                } text-[#777777] mb-1 flex items-center justify-between`}
                onClick={() => setTransFromOpen(!TransFromOpen)}
              >
                <p>
                  {transtalteFromInput
                    ? transtalteFromInput
                    : "Select source language"}
                </p>
                <span
                  className={`text-quotetext-200 arrow ${
                    TransFromOpen ? "rotate-[316deg]" : "rotate-[135deg]"
                  }  transition-all duration-200 ease-linear  `}
                ></span>
              </div>
              <div
                className={` bg-white transform top-0  right-0 w-full fixed h-[100vh] rounded-t-lg  lg:hidden overflow-auto ease-in-out transition-all duration-500 z-[99]
                  ${TransFromOpen ? "-translate-y-0" : "translate-y-full"}`}
              >
                <div className="bg-white rounded-t-lg text-black mt-2 flex justify-between px-[20px] w-full py-2 font-opensans text-[17px] font-semibold leading-6">
                  <p>Translate From</p>
                  <button
                    className="text-[#007AFF] text-[17px] font-opensans leading-[22px]"
                    onClick={() => handleOpClick()}
                    type="button"
                  >
                    Close
                  </button>
                </div>
                <div className="relative px-[20px] mt-[14px] mb-[15px] outline-none border-none">
                  <div className="absolute inset-y-0 start-4 flex items-center ps-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M6.3833 13.3767C7.76123 13.3767 9.04785 12.9285 10.0938 12.1814L14.0283 16.116C14.2109 16.2986 14.4517 16.3899 14.7007 16.3899C15.2402 16.3899 15.6304 15.9749 15.6304 15.4436C15.6304 15.1946 15.5474 14.9622 15.3647 14.7795L11.4551 10.8616C12.2769 9.78247 12.7666 8.44604 12.7666 6.99341C12.7666 3.48218 9.89453 0.610107 6.3833 0.610107C2.86377 0.610107 0 3.48218 0 6.99341C0 10.5046 2.86377 13.3767 6.3833 13.3767ZM6.3833 11.9988C3.63574 11.9988 1.37793 9.73267 1.37793 6.99341C1.37793 4.25415 3.63574 1.98804 6.3833 1.98804C9.12256 1.98804 11.3887 4.25415 11.3887 6.99341C11.3887 9.73267 9.12256 11.9988 6.3833 11.9988Z"
                        fill="#8E8E93"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    style={TranslateFromstyles}
                    className={` h-[50px] px-4 ps-10  z-51 bg-[#EEE] w-full rounded-lg outline-none focus:border-[#3F1573] border border-solid  text-[#777777] text-base flex items-center justify-between `}
                    placeholder="Search..."
                    value={FromSearchInput}
                    onChange={(e) => setFromSearchInput(e.target.value)}
                    required
                  />
                </div>

                <ul
                  className="px-[20px] h-[calc(100vh-0px)] overflow-auto "
                  style={TranslateFromstyles}
                >
                  {data
                    ? data?.data
                        ?.filter(({ source_language }) => {
                          if (FromSearchInput === "") {
                            return source_language;
                          } else if (
                            source_language
                              .toLowerCase()
                              .includes(FromSearchInput.toLowerCase())
                          ) {
                            return source_language;
                          }
                        })
                        ?.map(({ source_language, priority }) => (
                          <>
                            <li
                              onMouseEnter={() =>
                                handleOptionHover(source_language)
                              }
                              onMouseLeave={() => setHoveredItem(null)}
                              className={`h-[50px] px-4 w-full hover:bg-[#00173A] rounded-lg hover:text-white flex items-center justify-between ${
                                transtalteFromInput === source_language
                                  ? "bg-[#00173A] text-[#ffffff] font-semibold "
                                  : "text-quotetext-200"
                              }`}
                              onClick={() =>
                                handleTranslateFromInput(source_language)
                              }
                              value={source_language}
                            >
                              <p className="w-full flex justify-between cursor-pointer ">
                                {source_language}
                                {hoveredItem === transtalteFromInput ||
                                transtalteFromInput === source_language ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.5 15L12.8033 20.3033L23.4087 9.69666"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                ) : null}
                              </p>
                            </li>
                          </>
                        ))
                    : null}
                </ul>
              </div>
            </div>
          </div>

          {errorOne && (
            <span className="text-red">This field is required.</span>
          )}
          {sameLanguageError && (
            <span className="text-red">
              Please select a different language.
            </span>
          )}
        </div>
      </label>
    </>
  );
}

export default TranslateFrom;
